<template>
    <div class="trabajadores">
        <transition name="slide">
            <router-view></router-view>
        </transition>
    </div>
</template>

<script>
export default {
    metaInfo: {
        title: 'Trabajadores',
    },
}
</script>

<style lang="scss" scoped></style>
